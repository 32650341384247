import { template as template_5910ca7a7dd34f0bb421532b311292cd } from "@ember/template-compiler";
const SidebarSectionMessage = template_5910ca7a7dd34f0bb421532b311292cd(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
