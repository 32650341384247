import { template as template_e55d2a6d92244dc7a24a5821c7e19308 } from "@ember/template-compiler";
const WelcomeHeader = template_e55d2a6d92244dc7a24a5821c7e19308(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
