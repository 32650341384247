import { template as template_76d4efeb1b384dc1a2ed8d2db816ed83 } from "@ember/template-compiler";
const FKText = template_76d4efeb1b384dc1a2ed8d2db816ed83(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
