import { template as template_e1e6c19c9e8743d197f647b722391e37 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_e1e6c19c9e8743d197f647b722391e37(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
