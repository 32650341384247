import { template as template_39364be441484ac18eeda29de1ae2735 } from "@ember/template-compiler";
const FKLabel = template_39364be441484ac18eeda29de1ae2735(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
